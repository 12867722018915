import Ground from '../assets/image/cours.webp';
import FirstFloor from '../assets/image/1-etage.webp';
import SecondFloor from '../assets/image/2-etage.webp';
import ThirdFloor from '../assets/image/3-etage.webp';

export function getConstant(path, targetValue) {
  let array = CONSTANT;
  path.split('.').forEach((key) => {
    array = array[key];
  });
  if (array.find((c) => c.value === targetValue)) {
    return array.find((c) => c.value === targetValue);
  } else {
    return '';
  }
}

export const CONSTANT = {
  version: '1.2.1',
  maxImageSize: 20 * 1000000,
  backoffice: {
    tabs: [
      {
        label: 'Résidences',
        key: 'residency',
        icon: 'residency'
      }
    ],
    account: {
      stateTypes: [
        {
          label: 'Actif',
          value: true,
          bgColor: 'bg-custom-green'
        },
        {
          label: 'Inexistant',
          value: false,
          bgColor: 'bg-slate-500'
        }
      ]
    },
    residency: {
      typesFilter: [
        {
          label: 'Tout afficher',
          value: 'long,short,event,permanent'
        },
        {
          label: 'Séjours longs',
          value: 'long'
        },
        {
          label: 'Séjours courts',
          value: 'short'
        },
        {
          label: 'Rendez-vous publics',
          value: 'event'
        },
        {
          label: 'Permanent',
          value: 'permanent'
        }
      ],
      typeList: [
        {
          label: 'Séjour long',
          value: 'long'
        },
        {
          label: 'Séjour court',
          value: 'short'
        },
        {
          label: 'Rendez-vous public',
          value: 'event'
        },
        {
          label: 'Permanent',
          value: 'permanent'
        }
      ],
      stateList: [
        {
          label: 'Publié',
          value: 'published',
          bgColor: 'bg-custom-green'
        },
        {
          label: 'Brouillon',
          value: 'pending',
          bgColor: 'bg-custom-orange'
        }
      ]
    }
  },
  menuItems: [
    {
      id: 1,
      title: 'LES HÔTES',
      link: '/'
    },

    {
      id: 2,
      title: 'POURQUOI CE REGISTRE ? ',
      link: '/pourquoi-ce-registre'
    },

    {
      id: 3,
      title: 'ET APRÈS ?',
      link: '/et-apres'
    },

    {
      id: 4,
      title: 'ÉCONOMIE CONTRIBUTIVE',
      link: '/economie-contributive'
    },
    {
      id: 5,
      title: 'ARCHIVES',
      link: '/archives'
    }
  ],
  residencyFilterOptions: [
    {
      label: 'Tout afficher',
      dropdownLabel: 'Séjours & rendez-vous',
      value: 'long,short,event,permanent'
    },
    {
      label: 'Séjours longs',
      dropdownLabel: 'Séjours longs',
      value: 'long'
    },
    {
      label: 'Séjours courts',
      dropdownLabel: 'Séjours courts',
      value: 'short'
    },
    {
      label: 'Rendez-vous publics',
      dropdownLabel: 'Rendez-vous publics',
      value: 'event'
    },
    {
      label: 'Hôtes permanent',
      dropdownLabel: 'Permanent',
      value: 'permanent'
    }
  ],

  residencyFloor: [
    {
      label: 'Premier étage',
      value: 'first'
    },
    {
      label: 'Deuxième étage',
      value: 'second'
    },
    {
      label: 'Troisième étage',
      value: 'third'
    },
    { label: 'Rez-de-chaussée', value: 'ground' }
  ],
  typeList: [
    {
      label: 'séjour long',
      value: 'long'
    },
    {
      label: 'séjour court',
      value: 'short'
    },
    {
      label: 'les rendez-vous',
      value: 'event'
    },
    {
      label: 'Permanent',
      value: 'permanent'
    }
  ],

  buildingFloors: [
    {
      ref: 'main-plan',
      label: 'fermer'
    },
    {
      ref: 'roof',
      label: 'toit'
    },
    {
      ref: 'third',
      label: 'troisième étage',
      image: ThirdFloor
    },
    {
      ref: 'second',
      label: 'deuxième étage',
      image: SecondFloor
    },
    {
      ref: 'first',
      label: 'premier étage',
      image: FirstFloor
    },
    {
      ref: 'ground',
      label: 'la cour',
      image: Ground
    }
  ],
  buildingPlanRooms: [
    {
      id: 1,
      label: 'La Blanchisserie - La Teinturerie',
      slug: 'la-blanchisserie-la-teinturerie',
      positionY: 110,
      positionX: 440,
      mobilePositionY: 90,
      mobilePositionX: 235,
      RDpositionX: 330,
      RDpositionY: 55,
      mobileRDPositionY: 50,
      mobileRDPositionX: 240,
      floor: 'first',
      legendPositionY: -40,
      legendPositionX: -135,
      legendRDPositionY: -45,
      legendRDPositionX: -50
    },
    {
      id: 2,
      label: 'La Lingerie - La Buanderie',
      slug: 'la-lingerie-la-buanderie',
      positionY: 35,
      positionX: 330,
      mobilePositionY: 60,
      mobilePositionX: 190,
      mobileRDPositionY: 0,
      mobileRDPositionX: 190,
      RDpositionX: 200,
      RDpositionY: 0,
      floor: 'first',
      legendRDPositionY: -17,
      legendRDPositionX: -135
    },
    {
      id: 3,
      label: 'La Chambre 236',
      slug: 'la-chambre-236',
      positionY: 195,
      positionX: 440,
      mobilePositionY: 125,
      mobilePositionX: 210,
      mobileRDPositionY: 80,
      mobileRDPositionX: 215,
      RDpositionX: 290,
      RDpositionY: 110,
      floor: 'first',
      legendPositionY: 0,
      legendPositionX: -93,
      MPlegendPositionY: -3,
      MPlegendPositionX: -93,
      legendRDPositionY: -10,
      legendRDPositionX: -92
    },
    {
      id: 4,
      label: 'La Chambre 237',
      slug: 'la-chambre-237',
      positionY: 180,
      positionX: 470,
      mobilePositionY: 120,
      mobilePositionX: 225,
      mobileRDPositionY: 27,
      mobileRDPositionX: 235,
      RDpositionX: 312,
      RDpositionY: 45,
      floor: 'first',
      legendPositionY: 110,
      legendPositionX: -5,
      MPlegendPositionY: -15,
      MPlegendPositionX: -90,
      legendRDPositionY: 90,
      legendRDPositionX: -30
    },
    {
      id: 5,
      label: 'La Chambre 238',
      slug: 'la-chambre-238',
      positionY: 170,
      positionX: 500,
      mobilePositionY: 115,
      mobilePositionX: 240,
      mobileRDPositionY: 65,
      mobileRDPositionX: 235,
      RDpositionX: 325,
      RDpositionY: 90,
      floor: 'first',
      legendPositionY: 0,
      legendPositionX: -93,
      legendRDPositionY: -18,
      legendRDPositionX: -92
    },

    {
      id: 6,
      label: 'Le Foyer',
      slug: 'le-foyer',
      positionY: 170,
      positionX: 280,
      mobilePositionY: 110,
      mobilePositionX: 150,
      mobileRDPositionY: 58,
      mobileRDPositionX: 130,
      RDpositionX: 160,
      RDpositionY: 100,
      floor: 'first',
      legendRDPositionY: -25,
      legendRDPositionX: -50
    },
    {
      id: 7,
      label: 'La Grande Galerie',
      slug: 'la-grande-galerie',
      positionY: 250,
      positionX: 280,
      mobilePositionY: 145,
      mobilePositionX: 150,
      mobileRDPositionY: 120,
      mobileRDPositionX: 90,
      RDpositionX: 160,
      RDpositionY: 155,
      floor: 'first',
      legendRDPositionY: -5,
      legendRDPositionX: 30
    },
    {
      id: 8,
      label: 'La Petite Galerie',
      slug: 'la-petite-galerie',
      positionY: 215,
      positionX: 360,
      mobilePositionY: 125,
      mobilePositionX: 185,
      mobileRDPositionY: 93,
      mobileRDPositionX: 195,
      RDpositionX: 230,
      RDpositionY: 130,
      floor: 'first',
      legendRDPositionY: -10,
      legendRDPositionX: -90
    },
    {
      id: 9,
      label: 'La Conciergerie',
      slug: 'la-conciergerie',
      positionY: 300,
      positionX: 180,
      mobilePositionY: 170,
      mobilePositionX: 110,
      mobileRDPositionY: 140,
      mobileRDPositionX: 90,
      RDpositionX: 100,
      RDpositionY: 190,
      floor: 'first',
      legendRDPositionY: -5,
      legendRDPositionX: -50
    },
    {
      id: 10,
      label: 'La Rotonde',
      slug: 'la-rotonde',
      positionY: 260,
      positionX: 200,
      mobilePositionY: 150,
      mobilePositionX: 110,
      mobileRDPositionY: 115,
      mobileRDPositionX: 100,
      RDpositionX: 135,
      RDpositionY: 145,
      floor: 'first',
      color: '#96c1fa',
      legendPositionY: -22,
      legendPositionX: -80,
      legendRDPositionY: -15,
      legendRDPositionX: -70
    },
    {
      id: 11,
      label: "L'Édulab",
      slug: 'l-edulab',
      positionY: 140,
      positionX: 170,
      mobilePositionY: 90,
      mobilePositionX: 110,
      mobileRDPositionY: 70,
      mobileRDPositionX: 70,
      RDpositionX: 100,
      RDpositionY: 80,
      floor: 'first',
      legendRDPositionY: -15,
      legendRDPositionX: -50
    },
    {
      id: 12,
      label: 'Le Studio - La Garde Robe',
      slug: 'le-studio-la-garde-robe',
      positionY: 40,
      positionX: 330,
      mobilePositionY: 60,
      mobilePositionX: 190,
      mobileRDPositionY: 0,
      mobileRDPositionX: 190,
      RDpositionX: 200,
      RDpositionY: 10,
      floor: 'second',
      legendRDPositionY: -17,
      legendRDPositionX: -135
    },
    {
      id: 13,
      label: "La Suite - L'Antichambre",
      slug: 'la-suite-l-antichambre',
      positionY: 130,
      positionX: 490,
      mobilePositionY: 90,
      mobilePositionX: 235,
      mobileRDPositionY: 50,
      mobileRDPositionX: 240,
      RDpositionX: 350,
      RDpositionY: 60,
      floor: 'second',
      legendPositionY: -22,
      legendPositionX: -140,
      legendRDPositionY: -45,
      legendRDPositionX: -50
    },
    {
      id: 14,
      label: 'Appartements avec vue - Première moitié',
      slug: 'appartements-avec-vue-premiere-moitie',
      positionY: 250,
      positionX: 260,
      mobilePositionY: 145,
      mobilePositionX: 150,
      mobileRDPositionY: 115,
      mobileRDPositionX: 60,
      RDpositionX: 55,
      RDpositionY: 160,
      floor: 'second',
      legendPositionY: -12,
      legendPositionX: 140,
      legendRDPositionY: -5,
      legendRDPositionX: 70
    },
    {
      id: 15,
      label: 'Appartements avec vue - Deuxième moitié',
      slug: 'appartements-avec-vue-deuxieme-moitie',
      positionY: 195,
      positionX: 355,
      mobilePositionY: 120,
      mobilePositionX: 200,
      mobileRDPositionY: 85,
      mobileRDPositionX: 190,
      RDpositionX: 270,
      RDpositionY: 110,
      floor: 'second',
      legendPositionY: -30,
      legendPositionX: -150,
      legendRDPositionY: -45,
      legendRDPositionX: -80
    },
    {
      id: 16,
      label: 'La Grande Loge',
      slug: 'la-grande-loge',
      positionY: 315,
      positionX: 170,
      mobilePositionY: 165,
      mobilePositionX: 110,
      mobileRDPositionY: 145,
      mobileRDPositionX: 90,
      RDpositionX: 100,
      RDpositionY: 190,
      floor: 'second',
      legendRDPositionY: -5,
      legendRDPositionX: -50
    },

    {
      id: 17,
      label: "La Petite Loge - L'âlcove",
      slug: 'la-petite-loge-l-alcove',
      positionY: 270,
      positionX: 110,
      mobilePositionY: 150,
      mobilePositionX: 85,
      mobileRDPositionY: 117,
      mobileRDPositionX: 30,
      RDpositionX: 110,
      RDpositionY: 160,
      floor: 'second',
      legendPositionY: -15,
      legendPositionX: -120,
      legendRDPositionY: -25,
      legendRDPositionX: 0
    },
    {
      id: 18,
      label: 'Le Cabinet des Curiosités',
      slug: 'le-cabinet-des-curiosites',
      positionY: 200,
      positionX: 10,
      mobilePositionY: 125,
      mobilePositionX: 45,
      mobileRDPositionY: 85,
      mobileRDPositionX: -90,
      RDpositionX: 0,
      RDpositionY: 110,
      floor: 'second',
      legendRDPositionY: -11,
      legendRDPositionX: 125
    },
    {
      id: 19,
      label: 'Le Pigeonnier',
      slug: 'le-pigeonnier',
      positionY: 200,
      positionX: 40,
      mobilePositionY: 125,
      mobilePositionX: 45,
      mobileRDPositionY: 85,
      mobileRDPositionX: -45,
      RDpositionX: 10,
      RDpositionY: 110,
      floor: 'third',
      legendRDPositionY: -15,
      legendRDPositionX: 75
    },
    {
      id: 20,
      label: 'La Mansarde',
      slug: 'la-mansarde',
      positionY: 270,
      positionX: 130,
      mobilePositionY: 150,
      mobilePositionX: 85,
      mobileRDPositionY: 117,
      mobileRDPositionX: 0,
      RDpositionX: 55,
      RDpositionY: 160,
      floor: 'third',
      legendRDPositionY: -15,
      legendRDPositionX: 75
    },
    {
      id: 21,
      label: 'La Vigie - Le Grenier',
      slug: 'la-vigie-le-grenier',
      positionY: 300,
      positionX: 170,
      mobilePositionY: 165,
      mobilePositionX: 110,
      mobileRDPositionY: 145,
      mobileRDPositionX: 90,
      RDpositionX: 100,
      RDpositionY: 185,
      floor: 'third',
      legendRDPositionY: -5,
      legendRDPositionX: -50
    },
    {
      id: 22,
      label: 'La cour',
      slug: 'la-cour',
      positionY: 210,
      positionX: 200,
      mobilePositionY: 130,
      mobilePositionX: 110,
      mobileRDPositionY: 90,
      mobileRDPositionX: 90,
      RDpositionX: 130,
      RDpositionY: 130,
      floor: 'ground',
      legendRDPositionY: -5,
      legendRDPositionX: 0
    },
    {
      id: 23,
      label: 'Le Hall',
      slug: 'le-hall',
      positionY: 270,
      positionX: 130,
      mobilePositionY: 150,
      mobilePositionX: 70,
      mobileRDPositionY: 120,
      mobileRDPositionX: 50,
      RDpositionX: 80,
      RDpositionY: 155,
      floor: 'ground',
      legendRDPositionY: -5,
      legendRDPositionX: 0
    },
    {
      id: 24,
      label: 'La Place Pasteur',
      slug: 'la-place-pasteur',
      positionY: 330,
      positionX: 0,
      mobilePositionY: 175,
      mobilePositionX: 20,
      mobileRDPositionY: 160,
      mobileRDPositionX: 0,
      RDpositionX: 0,
      RDpositionY: 190,
      floor: 'ground',
      legendRDPositionY: -5,
      legendRDPositionX: 0
    }
  ],
  years: [2024, 2023, 2022, 2021],
  months: [
    {
      label: 'Janvier',
      slug: 'janvier',
      value: '01'
    },
    {
      label: 'Février',
      slug: 'fevrier',
      value: '02'
    },
    {
      label: 'Mars',
      slug: 'mars',
      value: '03'
    },
    {
      label: 'Avril',
      slug: 'avril',
      value: '04'
    },
    {
      label: 'Mai',
      slug: 'mai',
      value: '05'
    },
    {
      label: 'Juin',
      slug: 'juin',
      value: '06'
    },
    {
      label: 'Juillet',
      slug: 'juillet',
      value: '07'
    },
    {
      label: 'Août',
      slug: 'aout',
      value: '08'
    },
    {
      label: 'Septembre',
      slug: 'septembre',
      value: '09'
    },
    {
      label: 'Octobre',
      slug: 'octobre',
      value: '10'
    },
    {
      label: 'Novembre',
      slug: 'novembre',
      value: '11'
    },
    {
      label: 'Décembre',
      slug: 'decembre',
      value: '12'
    }
  ]
};
