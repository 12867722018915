import React, { useState, useEffect, useRef } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import FormInput from '../../../components/Backoffice/Form/FormInput';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import { getDateOrUndefined, getDateTimeStringOrUndefined } from '../../../utils/stringHelper';
import { usePropertyContext } from '../../../contexts/PropertyContext';
import { useRoomContext } from '../../../contexts/RoomContext';
import { privateFetch } from '../../../utils/apiHelper';
import slugify from 'react-slugify';
import { FileUpload } from 'primereact/fileupload';
import { CONSTANT } from '../../../utils/constants';
import RichTextEditor from '../../../components/Backoffice/Form/RichTextEditor';

function ManageResidencyEvent({
  isOpen,
  handleClose,
  updateEvent,
  createEvent,
  loading,
  residency,
  event
}) {
  const fileUploadRef = useRef(null);
  const { propertyContext } = usePropertyContext();
  const { roomContext } = useRoomContext();
  // Form
  const [label, setLabel] = useState('');
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [properties, setProperties] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [description, setDescription] = useState('');

  const [image, setImage] = useState(undefined);

  const state = 'published';

  useEffect(() => {
    if (event) {
      setLabel(event.label);
      setStartDate(getDateOrUndefined(event.start_dt));
      setEndDate(getDateOrUndefined(event.end_dt));
      setProperties(event.properties);
      setRooms(event.rooms);
      setDescription(event.description);
      setImage(event.image);
    } else {
      setLabel('');
      setStartDate(undefined);
      setEndDate(undefined);
      setProperties(residency.properties);
      setRooms(residency.rooms);
      setDescription('');
      setImage(residency.image);
    }
  }, [residency, event]);

  const uploadImage = async (imageForm) => {
    await privateFetch('POST', `/image/`, null, imageForm, false, true).then((res) => {
      setImage(res);
    });
  };

  const onUpload = (event) => {
    if (event.files.length === 1) {
      const file = event.files[0];
      const imageForm = new FormData();
      imageForm.append('name', slugify(file.name));
      imageForm.append('file', file);
      uploadImage(imageForm);
    }
  };

  const onClear = () => {
    setImage(undefined);
    fileUploadRef.current.clear();
  };

  const onValidate = () => {
    const eventForm = {
      label,
      start_dt: getDateTimeStringOrUndefined(startDate),
      end_dt: getDateTimeStringOrUndefined(endDate),
      description: description,
      properties: properties.map((p) => p.id),
      rooms: rooms.map((r) => r.id),
      image: image.id,
      state
    };
    event ? updateEvent(eventForm) : createEvent(eventForm);
  };

  const labelIsValid = label.length > 2;
  const descriptionIsValid = description.length > 10;
  const roomsIsValid = rooms.length;
  const propertiesIsValid = properties.length;

  const formIsValid =
    labelIsValid &&
    startDate &&
    endDate &&
    descriptionIsValid &&
    image &&
    roomsIsValid &&
    propertiesIsValid;

  const footerButtons = (
    <div className="manage-footer">
      <Button
        key={'validate'}
        label={`Valider ${event ? 'les modifications' : "l'ajout"}`}
        loading={loading}
        disabled={!formIsValid}
        onClick={onValidate}
        severity="success"
      />
    </div>
  );

  return (
    <Sidebar
      style={{ width: 800 }}
      header={
        <h3 className="text-2xl font-medium text-slate-800">{`${event ? 'Modifier' : 'Ajouter '} un évènement`}</h3>
      }
      visible={isOpen}
      maskStyle={{ backgroundColor: '#00000080' }}
      onHide={() => handleClose()}
      position="right">
      <div className="sidebar-content-container">
        <div className="form-wrapper">
          <FormInput key="label" label="Nom" required isValid={labelIsValid}>
            <InputText
              value={label}
              size={250}
              placeholder={`Veuillez saisir le nom de l'évènement`}
              onChange={(e) => setLabel(e.target.value)}
            />
          </FormInput>

          <FormInput label={'Date de début'} required isValid={startDate}>
            <Calendar
              value={startDate}
              hideOnDateTimeSelect
              onChange={(e) => setStartDate(e.value)}
              dateFormat="dd/mm/yy"
              placeholder="Veuillez selectionner la date"
              locale="fr"
              showTime
              stepMinute={15}
            />
          </FormInput>

          <FormInput label={'Date de fin'} required isValid={endDate}>
            <Calendar
              value={endDate}
              hideOnDateTimeSelect
              onChange={(e) => setEndDate(e.value)}
              dateFormat="dd/mm/yy"
              placeholder="Veuillez selectionner la date"
              locale="fr"
              showTime
              stepMinute={15}
            />
          </FormInput>

          <FormInput key="rooms" label={'Salles'} required isValid={roomsIsValid}>
            <MultiSelect
              id="rooms"
              key="rooms"
              value={rooms}
              onChange={(e) => setRooms(e.value)}
              options={roomContext}
              optionLabel="label"
              placeholder="Veuillez sélectionner une ou plusieurs salles"
              display="chip"
            />
          </FormInput>

          <FormInput key="properties" label={'Tags'} required isValid={propertiesIsValid}>
            <MultiSelect
              id="properties"
              value={properties}
              onChange={(e) => setProperties(e.value)}
              options={propertyContext}
              optionLabel="label"
              placeholder="Veuillez sélectionner des tags"
              display="chip"
            />
          </FormInput>

          <FormInput label={'Description'} required isValid={descriptionIsValid}>
            <RichTextEditor
              value={description}
              onChange={(value) => {
                setDescription(value);
              }}
              placeholder={"Veuillez saisir une description de l'évènement"}
            />
          </FormInput>

          <FormInput
            key={'file'}
            label={'Image'}
            required
            isValid={image}
            helpMessage={'20Mo max. et formats supportés : .jpg, .png'}>
            {image && (
              <img
                src={image.file}
                className="w-3/4 object-cover"
                alt="Image principale du séjour"
              />
            )}
            <div className="flex flex-row gap-x-3">
              {image && (
                <Button
                  key={'delete-img'}
                  label={"Supprimer l'image"}
                  outlined
                  onClick={onClear}
                  severity="danger"
                />
              )}
              <FileUpload
                ref={fileUploadRef}
                auto
                disabled={image}
                mode="basic"
                name="file"
                accept="image/*"
                maxFileSize={CONSTANT.maxImageSize}
                customUpload
                uploadHandler={onUpload}
                chooseLabel="Depuis mon ordinateur"
              />
            </div>
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManageResidencyEvent;
