import React, { useState, useEffect } from 'react';
import Menu from '../../../components/Frontoffice/Menu/Menu';
import Footer from '../../../components/Frontoffice/Footer/Footer';
import ResidencyDetail from '../../../components/Frontoffice/ResidencyDetail/ResidencyDetail';
import ResidencyList from '../../../components/Frontoffice/ResidencyListItem/ResidencyList';
import PillArchives from '../../../components/Frontoffice/Pill/PillArchives';
import { publicFetch } from '../../../utils/apiHelper';
import moment from 'moment';
import { CONSTANT, getConstant } from '../../../utils/constants';
import { InputText } from 'primereact/inputtext';
import { InputIcon } from 'primereact/inputicon';
import { IconField } from 'primereact/iconfield';
import slugify from 'react-slugify';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './ArchivesPage.scss';
import { ProgressSpinner } from 'primereact/progressspinner';

function ArchivesPage() {
  const [residencyList, setResidencyList] = useState([]);
  const [residencyDetail, setResidencyDetail] = useState(undefined);
  const [query, setQuery] = useState('');
  const [dateSelected, setDateSelected] = useState(
    moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
  );
  const [monthSelected, setMonthSelected] = useState(
    moment().subtract(1, 'month').endOf('month').format('MM')
  );
  const [yearSelected, setYearSelected] = useState(CONSTANT.years[0]);
  const [closing, setClosing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const fetchResidencyList = async () => {
    setLoading(true);
    await publicFetch(
      'GET',
      `/residency/?d=${dateSelected}&types=long,short,event,permanent${`&query=${slugify(query)}`}`
    ).then((response) => {
      setResidencyList(response);
      setLoading(false);
      setIsEmpty(
        Object.keys(response.past_residencies || {}).every(
          (type) => response.past_residencies[type]?.length === 0
        )
      );
    });
  };

  useEffect(() => {
    if (residencyList && residencyList.past_residencies && window.innerWidth > 950) {
      if (residencyList.past_residencies.long.length > 0) {
        setResidencyDetail(residencyList.past_residencies.long[0]);
      } else if (residencyList.past_residencies.short.length > 0) {
        setResidencyDetail(residencyList.past_residencies.short[0]);
      } else if (residencyList.past_residencies.event.length > 0) {
        setResidencyDetail(residencyList.past_residencies.event[0]);
      } else if (residencyList.past_residencies.permanent.length > 0) {
        setResidencyDetail(residencyList.past_residencies.permanent[0]);
      } else {
        setResidencyDetail(undefined);
      }
    }
  }, [residencyList]);

  const handleClose = () => {
    setClosing(true);
    setTimeout(() => {
      setResidencyDetail(undefined);
      setClosing(false);
    }, 300);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const yearParams = searchParams.get('y');
    const monthParams = searchParams.get('m');

    if (yearParams && monthParams) {
      setYearSelected(yearParams);
      setMonthSelected(monthParams);
    } else {
      setDateSelected(moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'));
    }
  }, [residencyList]);

  const fetchResidencyDetail = async (residencySlug) => {
    await publicFetch('GET', `/residency/${residencySlug}`).then((response) => {
      setResidencyDetail(response);
    });
  };

  const handleResidencyClick = (residency) => {
    fetchResidencyDetail(residency.slug);
  };

  const handleSelectMonth = (value) => {
    setMonthSelected(value);
    setQuery('');
  };

  const handleSelectYear = (value) => {
    setYearSelected(value);
    setQuery('');
  };

  const handleQuery = (string) => {
    setQuery(string);
    setMonthSelected(moment().subtract(1, 'month').endOf('month').format('MM'));
    setYearSelected(CONSTANT.years[0]);
  };

  console.log(monthSelected);

  useEffect(() => {
    setDateSelected(`${yearSelected}-${monthSelected}-01`);
  }, [yearSelected, monthSelected]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (dateSelected) {
        navigate(
          `/archives?m=${monthSelected}&y=${yearSelected}${query ? `&query=${slugify(query)}` : ''}`
        );
        fetchResidencyList();
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [dateSelected, query]);

  const header = (
    <IconField iconPosition="right">
      {query.length ? (
        <InputIcon className="pi pi-times cursor-pointer" onClick={() => setQuery('')}></InputIcon>
      ) : (
        <InputIcon className="pi pi-search"></InputIcon>
      )}
      <InputText
        type="text"
        placeholder="Recherchez par nom"
        className="searchbar"
        onChange={(e) => handleQuery(e.target.value)}
        value={query}
      />
    </IconField>
  );

  return (
    <div className="frontoffice-css-scope">
      <Menu />
      <div className="page-wrapper">
        <div className="archives-page">
          <div className="left-bloc">
            {header}
            <p>ou sélectionnez un mois :</p>
            <div className={`pills-wrapper ${query ? 'disabled' : 'abled'}`}>
              {CONSTANT.months.map((m, index) => (
                <PillArchives
                  label={m.label}
                  value={m.value}
                  handleClick={handleSelectMonth}
                  key={index}
                  isActive={monthSelected === m.value}
                />
              ))}
            </div>
            <div className={`pills-wrapper ${query ? 'disabled' : 'abled'}`}>
              {CONSTANT.years.map((y, index) => (
                <PillArchives
                  label={y}
                  value={y}
                  key={index}
                  isActive={yearSelected === y.toString()}
                  handleClick={handleSelectYear}
                />
              ))}
            </div>
          </div>
          <div className="center-bloc">
            {loading ? (
              <div className="loading-wrapper">
                <ProgressSpinner
                  style={{ width: '50px', height: '50px' }}
                  strokeWidth="4"
                  animationDuration=".5s"
                />
              </div>
            ) : !isEmpty ? (
              Object.keys(residencyList.past_residencies || {}).map((type) => (
                <div className="" key={type}>
                  {residencyList.past_residencies[type].length === 0 ? null : (
                    <div className="type">{getConstant('typeList', type).label}</div>
                  )}
                  <ResidencyList
                    residencies={residencyList.past_residencies[type]}
                    handleResidencyClick={handleResidencyClick}
                    residencyDetail={residencyDetail}
                  />
                </div>
              ))
            ) : (
              <div className="no-results">
                aucun séjour ni rendez-vous ne correspond à votre recherche
              </div>
            )}
          </div>
          <div className="right-bloc">
            {residencyDetail && !loading && (
              <ResidencyDetail
                residencyDetail={residencyDetail}
                closing={closing}
                setClosing={setClosing}
                handleResidencyClick={handleResidencyClick}
                setResidencyDetail={setResidencyDetail}
                inArchivePage
                handleClose={handleClose}
              />
            )}

            {loading && (
              <div className="loading-wrapper desktop">
                <ProgressSpinner
                  style={{ width: '50px', height: '50px' }}
                  strokeWidth="4"
                  animationDuration=".5s"
                />
              </div>
            )}

            {!residencyDetail && window.innerWidth > 950 && isEmpty && !loading && (
              <div className="no-results">
                aucun séjour ni rendez-vous ne correspond à votre recherche
              </div>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default ArchivesPage;
