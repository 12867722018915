import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import ResidencyPage from './Residency/ResidencyPage';
import DashboardPage from './Dashboard/DashboardPage';
import AdminPage from './Admin/AdminPage';
import { useAccountContext } from '../../contexts/AccountContext';
import { useNavigationContext } from '../../contexts/NavigationContext';
import useScreenSize from '../../hooks/useScreenSize';

function Router() {
  const { isAdmin, accountContext } = useAccountContext();
  const { openResidencyDetail } = useNavigationContext();
  const screenSize = useScreenSize();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAdmin) {
      openResidencyDetail(accountContext.residency);
    }
  }, []);

  useEffect(() => {
    if (screenSize.width < 700) {
      navigate('/mobile-catcher');
    }
  }, [screenSize.width]);

  return (
    <Routes>
      <Route exact path="/private/residency" element={<ResidencyPage />} />
      {isAdmin && <Route exact path="/private/dashboard" element={<DashboardPage />} />}
      {isAdmin && <Route exact path="/private/admin" element={<AdminPage />} />}
    </Routes>
  );
}

export default Router;
