import React, { useEffect } from 'react';
import NavbarItem from './NavbarItem';
import { useNavigate, useNavigation } from 'react-router-dom';
import { useAccountContext } from '../../../contexts/AccountContext';
import { useNavigationContext } from '../../../contexts/NavigationContext';
import { saveSession } from '../../../utils/sessionHelper';
import CustomIcon from '../CustomIcon';
import NavbarIconItem from './LogoutItem';
import SearchBar from '../Search/SearchBar';

function Navbar() {
  const navigate = useNavigate();
  const { navigationContext, setNavigationContext } = useNavigationContext();
  const { setAccountContext, isAdmin } = useAccountContext();

  useEffect(() => {
    navigationContext &&
      navigationContext.activeApp &&
      navigate(`/private/${navigationContext.activeApp}`);
  }, [navigationContext?.activeApp]);

  const navigateToApp = (app) => {
    setNavigationContext({
      ...navigationContext,
      activeApp: app
    });
  };

  const handleSignOut = () => {
    setAccountContext(null);
    saveSession(null);
    navigate('/login');
  };

  const handleRedirectToWebsite = () => {
    //navigate('/archives?query=collectif-node');
    navigate('/');
  };

  return (
    <div className="w-full h-16 bg-slate-800 flex flex-row justify-between items-center pl-5">
      <div className="flex flex-row items-center gap-x-5 w-1/4 pr-3">
        <CustomIcon
          icon="logo"
          alt="Logo de l'Hotel Pasteur"
          className="h-8 w-8 text-white hover:text-yellow-500 cursor-pointer"
          onClick={() => isAdmin && navigateToApp('dashboard')}
        />
        {isAdmin ? (
          <SearchBar />
        ) : (
          <span className="text-lg text-white font-normal">Registre de l'Hôtel Pasteur</span>
        )}
      </div>

      <div className="flex flex-row gap-x-5 h-full items-center">
        {isAdmin && (
          <NavbarItem
            label={'Tableau de bord'}
            icon={'dashboard'}
            key={'dashboard'}
            isActive={navigationContext.activeApp === 'dashboard'}
            onClick={() => navigateToApp('dashboard')}
          />
        )}
        {navigationContext.activeResidency?.id && (
          <NavbarItem
            label={navigationContext.activeResidency.label}
            icon={'residency'}
            isActive={navigationContext.activeApp === 'residency'}
            onClick={() => navigateToApp('residency')}
          />
        )}
      </div>

      <div className="flex flex-row h-full justify-end w-1/4">
        {/* isAdmin && (
          <NavbarIconItem
            key="admin"
            onClick={() => navigateToApp('admin')}
            icon="admin"
            isActive={navigationContext.activeApp === 'admin'}
          />
        ) */}
        <NavbarIconItem key="website" onClick={handleRedirectToWebsite} icon="website" />
        <NavbarIconItem key="logout" onClick={handleSignOut} icon="logout" />
      </div>
    </div>
  );
}

export default Navbar;
