import React, { useState, useEffect } from 'react';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { privateFetch, publicFetch, refreshAccesToken } from '../../utils/apiHelper';
import { saveSession } from '../../utils/sessionHelper';
import FormInput from '../../components/Backoffice/Form/FormInput';
import { validateEmail } from '../../utils/stringHelper';
import { useAccountContext } from '../../contexts/AccountContext';
import Layout from './Layout';
import useScreenSize from '../../hooks/useScreenSize';

function SignIn() {
  const { isLoggedIn, accountContext, setAccountContext, isAdmin } = useAccountContext();
  const screenSize = useScreenSize();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    refreshSession().then((isSessionValid) => {
      if (isSessionValid) {
        fetchAccountDetail();
      }
    });
  }, []);

  useEffect(() => {
    if (screenSize.width < 700) {
      navigate('/private/mobile-catcher');
    }
  }, [screenSize.width]);

  useEffect(() => {
    if (isLoggedIn) {
      if (isAdmin) {
        navigate('/private/dashboard');
      } else {
        navigate('/private/residency');
      }
    }
  }, [accountContext]);

  const fetchAccountDetail = async () => {
    await privateFetch('GET', `/me/`).then((response) => {
      setAccountContext(response);
    });
  };

  const refreshSession = async () => {
    return await refreshAccesToken();
  };

  const handleLogin = async () => {
    setLoading(true);
    await publicFetch(
      'POST',
      '/user/token/',
      'Les informations de connexion sont erronées. Veuillez réessayer.',
      JSON.stringify({
        username: email,
        password
      })
    ).then((response) => {
      if (!response) {
        setLoading(false);
      } else {
        saveSession(response);
        fetchAccountDetail();
      }
    });
  };

  const passwordIsValid = () => {
    return password.length > 7;
  };

  const emailIsValid = () => {
    return validateEmail(email);
  };

  const recoverPasswordButton = (
    <span
      className="cursor-pointer hover:text-custom-orange"
      onClick={() => navigate('/recover-password')}>
      Mot de passe oublié ?
    </span>
  );

  return (
    <Layout title={'Se connecter à votre compte'}>
      <div className="flex flex-col gap-3">
        <FormInput label={'Adresse mail'} required isValid={emailIsValid()}>
          <IconField iconPosition="left">
            <InputIcon className="pi pi-at"> </InputIcon>
            <InputText
              id="email"
              type="text"
              placeholder="Entrez votre adresse email"
              className="w-full"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </IconField>
        </FormInput>

        <FormInput
          label={'Mot de passe'}
          required
          isValid={passwordIsValid()}
          helpMessage={recoverPasswordButton}>
          <IconField iconPosition="left">
            <InputIcon className="pi pi-lock"> </InputIcon>
            <InputText
              id="password"
              type="password"
              placeholder="Entrez votre mot de passe"
              className="w-full"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </IconField>
        </FormInput>
      </div>

      <Button
        label="Se connecter"
        icon="pi pi-user"
        className="btn"
        loading={loading}
        disabled={!passwordIsValid() || !emailIsValid()}
        onClick={handleLogin}
      />
    </Layout>
  );
}

export default SignIn;
