import React from 'react';
import Navbar from '../../../components/Backoffice/Navbar/Navbar';

function AdminPage() {
  return (
    <div className={`h-screen w-screen bg-white flex flex-col`}>
      <Navbar />
      Admin Page
    </div>
  );
}

export default AdminPage;
