import Amenagement202401 from '../assets/image/amenagement202401.webp';
import Amenagement202402 from '../assets/image/amenagement202402.webp';
import Amenagement202403 from '../assets/image/amenagement202403.webp';
import Amenagement202404 from '../assets/image/amenagement202404.webp';
import Gouvernance202401 from '../assets/image/Gouvernance202401.webp';
import Gouvernance202402 from '../assets/image/Gouvernance202402.webp';
import Disposition202401 from '../assets/image/Disposition202401.webp';
import Disposition202402 from '../assets/image/Disposition202402.webp';
import Disposition202404 from '../assets/image/Disposition202404.webp';
import Action202401 from '../assets/image/Action202401.webp';
import Action202402 from '../assets/image/Action202402.webp';
import Action202403 from '../assets/image/Action202403.webp';
import Transmission202401 from '../assets/image/Transmission202401.webp';
import Transmission202402 from '../assets/image/Transmission202402.webp';
import Transmission202403 from '../assets/image/Transmission202403.webp';
import Transmission202404 from '../assets/image/Transmission202404.webp';
import Transmission202405 from '../assets/image/Transmission202405.webp';
import Transmission202406 from '../assets/image/Transmission202406.webp';
import Transmission202407 from '../assets/image/Transmission202407.webp';
import Amenagement202301 from '../assets/image/Amenagement202301.webp';
import Amenagement202302 from '../assets/image/Amenagement202302.webp';
import Amenagement202303 from '../assets/image/Amenagement202303.webp';
import Gouvernance202301 from '../assets/image/Gouvernance202301.webp';
import Gouvernance202302 from '../assets/image/Gouvernance202302.webp';
import Disposition202301 from '../assets/image/Disposition202301.webp';
import Action202301 from '../assets/image/Action202301.webp';
import Action202302 from '../assets/image/Action202302.webp';
import Transmission202301 from '../assets/image/Transmission202301.webp';
import Transmission202302 from '../assets/image/Transmission202302.webp';

export const DATA = {
  version: '1.0.0',
  economyItems: [
    {
      year: '2024',
      legend:
        "Une année de plus pour l'Hôtel Pasteur. La Conciergerie souhaite remercier l'ensemble des hôtes, porteureuses de projet, intervenant·es ou simples visiteureuses qui, cette année encore, ont fait vivre le lieu.",
      economyContent: [
        {
          title: 'entretien et aménagement',

          content:
            '<p>✧ 460 heures comptées en moyenne pour les Grands Coups de balais en 2024 (l’entretien des espaces communs - toilettes, foyer, circulation…) et 150 heures pour les Petits Coups de balais (entretien des espaces personnels avant la passation) <br/><br />✧ Embellissement et signalétique conçus par les hôtes (photo ✧ Signalétique circulation dans le bâtiment d’Alexandre Lequeur (8h et 1 personne)<br/><br />✧ Remise en état des portes des placards de la cuisine (16 heures) <br/><br />✧ Des tours à la déchetterie, ponçage, réparations de meubles, tiroirs, portes de placard et étagères. (photos Réparations placards à balais, portes de placards de la cuisine), menus travaux / <br/><br />✧ Tri de la vaisselle et inventaire (la fraterie du quartier) / 8 heures <br/><br />✧ Fabrication de manics en feutre pour la cuisine (laisse mouton) / 4 heures<br/><br />✧ Fabrication fanions / 7 heures</p> ',
          images: [
            {
              name: Amenagement202401,
              alt: 'Aménagement du placard-à-balais - Collectif Mangata'
            },
            {
              name: Amenagement202402,
              alt: 'Aménagements de la cuisine - Malek Hôte du lieu'
            },
            {
              name: Amenagement202403,
              alt: 'Aménagements de la cuisine - Malek Hôte du lieu'
            },
            {
              name: Amenagement202404,
              alt: 'Peintures à côté des ascenceurs - Capletter et Toutfeutoutfemme'
            }
          ]
        },
        {
          title: 'documentation et recherche-action',
          legend:
            '<p>Donner à voir et à comprendre l’Hôtel Pasteur, en documentant les expériences pour qu’elles puissent être partagées et discutées, afin de continuer d’évoluer et de renouveler nos manières de faire.<br />Iels contribuent aux réflexions en documentant leurs séjours et en contribuant à nourrir les axes de recherche-action portés par l’association ; “questionner les normes, jouer l’école en commun, fabrique de la Ville solidaire.”</p>',

          content:
            '<p>✧ Participation atelier de cartographie colloque IEP (Melissa) et MAMI (équivalent 15 heures)<br/><br />✧ Contribution à l’atelier : Atelier pop up care lié à une recherche de l’Hôtel Pasteur avec Antoinette Parrau,12 heures <br/><br />✧ Atelier Tissage : participation à l’atelier carto Tissage (18)<br/><br />✧ Temps d’entretiens lors des séjours et temps de documentation dans le registre (40)</p>',
          images: [
            {
              name: Action202401,
              alt: 'Atelier cartographie tissage_ Antoinette Parrau et la conciergerie'
            },
            {
              name: Action202402,
              alt: 'Atelier cartographie tissage_ Antoinette Parrau et la conciergerie'
            },
            {
              name: Action202403,
              alt: 'Atelier pop up care 3_ Antoinette Parrau'
            }
          ]
        },
        {
          title: 'gouvernance',
          legend:
            "<p>La gouvernance de l'association est collégiale et ouverte.<br />Ce sont les comités, ouverts aux forces vives qui le souhaitent, qui travaillent à différents sujets et questions qui animent l’association et le quotidien du lieu. <br/>Ce sont les membres du conseil collégial qui s’impliquent dans la gestion et dans les réflexions qui constituent le projet associatif.</p>",

          content:
            '<p>Participation à la gouvernance de l’association : <ul><li>✧ comité employeur </li><li>✧ comité carie </li><li>✧ comité du 2 </li><li>✧ conseil collégiaux et AG </li><li>✧ comité “être Partenaire de Pasteur”</li></ul></p>',
          images: [
            {
              name: Gouvernance202401,
              alt: "Conseil collégial d'automne"
            },
            {
              name: Gouvernance202402,
              alt: 'Le comité carie'
            }
          ]
        },
        {
          title: 'mise à disposition et don',
          legend:
            "<p>A L'Hôtel Pasteur, rien ne se jette, tout se ré-emploi. Chaque objet raconte son histoire, construit pour le commun, ramené de chez soi pour entamer une nouvelle trajectoire, continuer son parcours et être utile à toustes.</p>",
          content:
            '<p>✧ Don de tasses, casserole, micro-onde, pot de peinture, chaises <br/><br/> ✧ Prêt d’un ordinateur, de matériel de visio<br/><br/> ✧  Réserve de Papiers de média graphique (2000€)</p>',
          images: [
            {
              name: Disposition202401,
              alt: 'Dons de tasses - Lila'
            },
            {
              name: Disposition202402,
              alt: 'Réserve de Papiers de média graphique'
            },
            {
              name: Disposition202404,
              alt: 'Manics pour le cuisine_ Laisse mouton'
            }
          ]
        },
        {
          title: 'transmission de savoir et compétences, veille',
          legend:
            '<p>Chaque hôte peut contribuer en mettant ses compétences au service d’un besoin du lieu et en les transmettant pour outiller les autres à travers, par exemple, l’animation d’ateliers ou des interventions.<br/>Les hôtes accueilli·es se font hôtes accueillant·es et relaient la conciergerie pour faire découvrir le lieu aux curieuxses de passage, transmettre l’histoire du bâtiment et veiller sur son quotidien.</p>',
          content:
            "<p>✧ Ateliers de couture, de poterie, de peinture, de risographie, de cuisine, d’art plastique à destination des curieuxses, des enfants de l’école maternelle Pasteur, des hôtes.<br /><br />✧ Partage d'expérience sur la vie de la cuisine avec la cloche (6 heures)<br />✧ Une formation sécurité pour les hôtes.<br />✧ 420 heures sur l’année passées par les hôtes à ouvrir leurs portes et raconter Pasteur et leurs séjours.<br/><br />✧ Délégation de la conciergerie lors d'un séminaire d'équipe / 8 heures <br/><br />✧ RDV du 2 (3 évènements) participation et co organisation avec les hôtes concernées / 272 heures mise en place zone de gratuité à Pasteur </p>",
          images: [
            {
              name: Transmission202401,
              alt: 'Balade plantes comestibles_ Par chemins'
            },
            {
              name: Transmission202402,
              alt: 'Balade reconnaissance de plantes comestibles_ Par chemins '
            },
            {
              name: Transmission202403,
              alt: 'Temps de découverte du travail de Nicolas Huchet de my human kit'
            },
            {
              name: Transmission202404,
              alt: 'Temps de découverte du travail de Nicolas Huchet de my human kit'
            },
            {
              name: Transmission202405,
              alt: 'Visite et découverte de la petite californie et informations sur les crues de la vilaine_ La Dreal Bretagne'
            },
            {
              name: Transmission202406,
              alt: 'Visite-et-découverte-de-la-petite-californie-et-informations-sur-les-crues-de-la-vilaine_-La-Dreal'
            },
            {
              name: Transmission202407,
              alt: 'Visite et découverte de la petite californie et informations sur les crues de la vilaine_ La Dreal Bretagne'
            }
          ]
        }
      ]
    },
    {
      year: '2023',
      legend:
        "Une année de plus pour l'Hôtel Pasteur. La Conciergerie souhaite remercier l'ensemble des hôtes, porteureuses de projet, intervenant·es ou simples visiteureuses qui, cette année encore, ont fait vivre le lieu.",
      economyContent: [
        {
          title: 'entretiens et aménagements',
          legend:
            "<p>À l'Hôtel Pasteur, chacun·e s’approprie les espaces, en prend soin et contribue à la valeur d’usage du lieu avant de le transmettre à d’autres. <br/>Cela passe par l’entretien des parties communes, la construction de mobiliers, l’aménagement, de menues réparations, prendre soin des plantes ...</p>",
          content:
            '<p>✧ 414 heures comptées en moyenne pour les Grands Coups de balais en 2023 (l’entretien des espaces communs - toilettes, foyer, circulation…) et 150 heures pour les Petits Coups de balais (entretien des espaces personnels avant la passation) <br/><br/>✧ Des tours à la déchetterie, la livraison d’un canapé fait maison, du ponçage, de la lasure, des réparations de meubles, tiroirs, portes de placard et étagères.<br/><br/> ✧ Embellissement avec une guirlande de fanions et fleurs tricotées conçues par des hôtes et signalétiques peintes sur les portes, dans les cages d’ascenseur et cousues en tentures.</p>',
          images: [
            {
              name: Amenagement202301,
              alt: 'Chantier participatif panneau acoustique _Collectif des festivals en Bretagne'
            },
            {
              name: Amenagement202302,
              alt: 'Fresque murale_Collectif élèves protégés Mahée Auffret'
            },
            {
              name: Amenagement202303,
              alt: 'Peinture de portes_Collectif La Vilaine'
            }
          ]
        },
        {
          title: 'documentation et recherche-action',
          legend:
            '<p>Donner à voir et à comprendre l’Hôtel Pasteur, en documentant les expériences pour qu’elles puissent être partagées et discutées, afin de continuer d’évoluer et de renouveler nos manières de faire.<br/>Iels contribuent aux réflexions en documentant leurs séjours et en contribuant à nourrir les axes de recherche-action portés par l’association ; “questionner les normes, jouer l’école en commun, fabrique de la Ville solidaire.”</p>',
          content:
            '<p>✧ Création du questionnaire alimentant La Mesure d’impact. (hyperlien) <br/><br/>✧ documentation d’un séjour de 3 mois avec une gazette hebdomadaire racontant la vie du lieu et de ses hôtes</p>',
          images: [
            {
              name: Action202301,
              alt: 'Atelier cartographie_ Participation à une recherche action'
            },
            {
              name: Action202302,
              alt: 'Atelier cartographie_ Participation à une recherche action'
            }
          ]
        },
        {
          title: 'gouvernance',
          legend:
            "<p>La gouvernance de l'association est collégiale et ouverte.<br />Ce sont les comités, ouverts aux forces vives qui le souhaitent, qui travaillent à différents sujets et questions qui animent l’association et le quotidien du lieu. <br/>Ce sont les membres du conseil collégial qui s’impliquent dans la gestion et dans les réflexions qui constituent le projet associatif.</p>",
          content: '<p>✧ 279.5 heures de participation à la gouvernance de l’association</p>',
          images: [
            {
              name: Gouvernance202301,
              alt: '10 ans de Pasteur'
            },
            {
              name: Gouvernance202302,
              alt: 'Pasteur en fête_Atelier du Conseil Collégial'
            }
          ]
        },
        {
          title: 'mise à disposition et don',
          legend:
            "<p>A L'Hôtel Pasteur, rien ne se jette, tout se ré-emploi. Chaque objet raconte son histoire, construit pour le commun, ramené de chez soi pour entamer une nouvelle trajectoire, continuer son parcours et être utile à toustes.</p>",
          content:
            '<p>✧ Don de tasses, casserole, micro-onde, pot de peinture, chaises <br/><br/> ✧ Prêt d’un ordinateur, de matériel de visio</p>',
          images: [
            {
              name: Disposition202301,
              alt: 'Fabrication de bols pour la cuisine de Pasteur_ Céramiques mortelles'
            }
          ]
        },
        {
          title: 'transmission de savoir et compétences, veille',
          legend:
            '<p>Chaque hôte peut contribuer en mettant ses compétences au service d’un besoin du lieu et en les transmettant pour outiller les autres à travers, par exemple, l’animation d’ateliers ou des interventions.<br/>Les hôtes accueilli·es se font hôtes accueillant·es et relaient la conciergerie pour faire découvrir le lieu aux curieuxses de passage, transmettre l’histoire du bâtiment et veiller sur son quotidien.</p>',
          content:
            '<p>✧ Ateliers de couture, de poterie, de peinture, de risographie, de cuisine, d’art plastique à destination des curieuxses, des enfants de l’école maternelle Pasteur, des hôtes.<br/><br/>✧ Une formation sécurité pour les hôtes.<br/><br/>✧ 270h sur l’année passées par les hôtes à ouvrir leurs portes et raconter Pasteur et leurs séjours.</p>',
          images: [
            {
              name: Transmission202301,
              alt: "Atelier avec l'école maternelle_ 3ème ciel"
            },
            {
              name: Transmission202302,
              alt: 'Atelier céramique_ Céramiques mortelles'
            }
          ]
        }
      ]
    }
  ]
};
